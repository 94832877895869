import { useMediaQuery } from '@finn/design-system/helpers/media';
import { cn } from '@finn/ui-utils';
import Script from 'next/script';
import React, { useCallback, useEffect } from 'react';

import { useCurrentLocale } from '~/hooks/useCurrentLocale';

export enum TrustpilotStyle {
  Stepper,
  Testimonial,
  PDP,
  Checkout,
}

const BUSINESSUNIT_ID = '640254b4583b1792de627f2b';

const styles: Record<TrustpilotStyle, { desktop: string; mobile: string }> = {
  [TrustpilotStyle.Stepper]: {
    desktop: '5406e65db0d04a09e042d5fc',
    mobile: '53aa8807dec7e10d38f59f32',
  },
  [TrustpilotStyle.Testimonial]: {
    desktop: '5419b6ffb0d04a076446a9af',
    mobile: '5419b637fa0340045cd0c936',
  },
  [TrustpilotStyle.PDP]: {
    desktop: '5419b637fa0340045cd0c936',
    mobile: '5419b637fa0340045cd0c936',
  },
  [TrustpilotStyle.Checkout]: {
    desktop: '5419b637fa0340045cd0c936',
    mobile: '5419b637fa0340045cd0c936',
  },
};

interface IProps {
  style: TrustpilotStyle;
  width?: string;
  mobileWidth?: string;
  mobileHeight?: string;
  desktopHeight?: string;
  mobileClassName?: string;
  desktopClassName?: string;
}

export const TrustPilotRating: React.FunctionComponent<IProps> = ({
  style,
  width = '100%',
  mobileHeight = '28px',
  desktopHeight = '28px',
  mobileWidth = '100%',
  mobileClassName,
  desktopClassName,
}) => {
  const { locale } = useCurrentLocale();

  const widgetRef = React.useRef<HTMLDivElement>(null);

  const isMobile = useMediaQuery('sm');

  const onload = useCallback(() => {
    if (window.Trustpilot && widgetRef.current) {
      window.Trustpilot.loadFromElement(widgetRef.current, true);
    }
  }, []);

  useEffect(onload, [widgetRef.current]);

  return (
    <>
      <Script
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        strategy="lazyOnload"
        onLoad={onload}
      />
      <a href="https://de.trustpilot.com/review/finn.com">
        <div
          ref={widgetRef}
          className={cn(
            'trustpilot-widget pointer-events-none min-h-7 contain-content',
            isMobile ? mobileClassName : desktopClassName
          )}
          data-template-id={
            isMobile ? styles[style].mobile : styles[style].desktop
          }
          data-businessunit-id={BUSINESSUNIT_ID}
          data-style-height={isMobile ? mobileHeight : desktopHeight}
          data-locale={locale}
          data-style-width={isMobile ? mobileWidth : width}
          data-theme="light"
        />
      </a>
    </>
  );
};
