import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
} from '@finn/design-system/atoms/modal';
import { TrackingEventName, useTrackingStore } from '@finn/ua-tracking';
import { Image } from '@finn/ui-components';
import { getCloudinaryImgUrl, getCloudinarySrcSet } from '@finn/ui-utils';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { useGetPreviousCart } from '~/modules/checkout/hooks/useGetPreviousCart';
import { useGetPreviousDeal } from '~/modules/checkout/hooks/useGetPreviousDeal';

import { AttributesBlock } from './AttributesBlock';
import { ContinueButton } from './ContinueButton';

type Props = {
  open: boolean;
  onClose(): void;
};

export const ReturnToCheckoutModal: React.FC<Props> = ({ open, onClose }) => {
  const i18n = useIntl();
  const { data: dealData } = useGetPreviousDeal();
  const previousCart = useGetPreviousCart();
  const track = useTrackingStore((state) => state.track);
  const data = dealData || previousCart;

  const title = i18n.formatMessage({
    id: 'checkout.returnToCheckout.title',
  });
  const stillInterested = i18n.formatMessage({
    id: 'checkout.returnToCheckout.stillIntersted',
  });

  const cloudinaryURL = getCloudinaryImgUrl(data?.vehicleImage || '', {
    w: 400,
    dpr: 2,
  });
  const srcSet = getCloudinarySrcSet(data?.vehicleImage || '', [400]);

  useEffect(() => {
    track(TrackingEventName.BASKET_VIEWED, {
      location: 'full-basket',
    });
  }, []);

  return (
    <Modal open={open} onOpenChange={(isOpen) => !isOpen && onClose()}>
      <ModalContent className="z-[1302]">
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
        </ModalHeader>
        <div className="bg-snow p-6">
          <div className="flex">
            <div className="flex shrink-0 grow-0 basis-2/5 flex-col gap-4 sm:basis-1/2 sm:gap-6">
              <span className="body-14-semibold">{stillInterested}</span>
              <h5 className="global-t5-semibold">
                {data?.brand} {data?.model}
              </h5>

              <div className="hidden sm:block">
                <AttributesBlock />
                <ContinueButton onClose={onClose} />
              </div>
            </div>
            <div className="shrink-0 grow-0 basis-3/5 sm:basis-1/2">
              <Image
                className="max-w-full"
                src={cloudinaryURL}
                srcSet={srcSet}
                alt={`${data?.brand} ${data?.model}`}
              />
            </div>
          </div>
          <div className="block sm:hidden">
            <AttributesBlock />
            <ContinueButton onClose={onClose} />
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
