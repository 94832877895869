import { Logo } from '@finn/design-system/icons/logo';
import { MinimisedFooterData } from '@finn/ui-cosmic';
import Link from 'next/link';
import { useIntl } from 'react-intl';

type MinimisedFooterProps = {
  data: MinimisedFooterData;
};

const MinimisedFooter = ({ data }: MinimisedFooterProps) => {
  const i18n = useIntl();

  return (
    <footer className="bg-black pb-8 pt-12 text-white">
      <div className="container">
        <div className="flex-wrap items-center md:flex md:justify-between">
          <div className="flex flex-col gap-4 pb-8 md:order-2 md:flex-row md:items-center md:gap-12">
            {data?.metadata?.privacy?.map((item) => (
              <Link
                key={item?.slug}
                href={item?.metadata?.href}
                target="_blank"
              >
                <a className="body-14-regular md:body-16-regular text-white">
                  {item?.metadata?.label}
                </a>
              </Link>
            ))}
          </div>
          <div>
            <Logo className="fill-white" />
            <p className="body-12-regular mt-4">
              {i18n.formatMessage({ id: 'general.copyright' })}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default MinimisedFooter;
