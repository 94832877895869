import { SpacingWrapper } from '@finn/platform-modules';
import { Header } from '@finn/ua-header';
import { ModalContainer, ModalKey } from '@finn/ua-modals';
import { ErrorBoundary } from '@finn/ui-components';
import { BasePageData, MinimisedFooterData } from '@finn/ui-cosmic';
import { isMobileApp } from '@finn/ui-utils';
import React from 'react';

import Footer from '~/components/Footer';
import CheckoutFooter from '~/components/Footer/CheckoutFooter';
import { CartButton } from '~/modules/checkout/components/CartButton/CartButton';
import { LoginModal } from '~/modules/login/LoginModal';

import MinimisedFooter from '../components/Footer/MinimisedFooter';

interface IProps {
  data: BasePageData;
  checkoutFooter?: boolean;
  hideHeaderFooter?: boolean;
  isMobile?: boolean;
}

const DefaultLayout: React.FunctionComponent<IProps> = ({
  children,
  data,
  checkoutFooter,
  hideHeaderFooter,
  isMobile,
}) => {
  const headerData = data.metadata.header;
  const footerData = data.metadata.footer;

  if (isMobile || isMobileApp() || hideHeaderFooter) {
    return (
      <div className="relative min-h-screen">
        {/*
          we want to mount invisible header, as we want to have
          some modals available that rendered inside header
        */}
        <div className="hidden">
          <ErrorBoundary>
            <Header data={headerData} CartButton={CartButton} />
          </ErrorBoundary>
        </div>
        <ModalContainer modalKey={ModalKey.LOGIN} ModalComponent={LoginModal} />
        {children}
      </div>
    );
  }

  const isB2BPageFooter = footerData?.slug?.includes?.('b2b');

  return (
    <div className="relative min-h-screen">
      <ErrorBoundary>
        <Header data={headerData} CartButton={CartButton} />
      </ErrorBoundary>
      <ModalContainer modalKey={ModalKey.LOGIN} ModalComponent={LoginModal} />
      {children}
      {checkoutFooter && (
        <ErrorBoundary>
          <div className="pb-24 sm:pb-16" />
          <CheckoutFooter />
        </ErrorBoundary>
      )}
      {isB2BPageFooter && (
        <ErrorBoundary>
          <SpacingWrapper>
            <MinimisedFooter
              data={footerData as unknown as MinimisedFooterData}
            />
          </SpacingWrapper>
        </ErrorBoundary>
      )}
      {!checkoutFooter && !isB2BPageFooter && (
        <ErrorBoundary>
          <Footer data={footerData} />
        </ErrorBoundary>
      )}
    </div>
  );
};

export default DefaultLayout;
