import React from 'react';

import { CheckoutFooterTopSection } from './CheckoutFooterTopSection';
import { TermsRowV2 } from './TermsRowV2';

const CheckoutFooter: React.FunctionComponent = () => {
  return (
    <div className="absolute bottom-0 w-full">
      <CheckoutFooterTopSection />
      <div className="bg-black py-6" data-nativeappid="checkout-footer-terms">
        <div className="container flex w-full flex-wrap">
          <TermsRowV2 />
        </div>
      </div>
    </div>
  );
};

export default CheckoutFooter;
